import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Button } from "../../reusableComponents/Button"
import contact from "../../../assets/images/contact.svg"
import emailjs from "emailjs-com"
import { Link } from "gatsby"
import messageSentVector from "../../../assets/images/icons/message-sent-vector.svg"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function Contact() {
  const [contactSent, setContactSent] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  const [message, setMessage] = useState("")
  const [formIsValid, setFormIsValid] = useState(false)

  const [error, setError] = useState("")
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    const isFormValid =
      Boolean(name.trim()) &&
      Boolean(email.trim()) &&
      Boolean(telephone.trim()) &&
      Boolean(message.trim())

    setFormIsValid(isFormValid)
  }, [name, email, telephone, message])

  const handleNameChange = e => setName(e.target.value)
  const handleMessageChange = e => setMessage(e.target.value)
  const handleEmailChange = e => setEmail(e.target.value)
  // const handleTelephoneChange = value => setTelephone(value || "")
  const handleTelephoneChange = e => {
    const value = e.target.value
    if (/^\d*$/.test(value)) {
      setTelephone(value)
      setError("")
    } else {
      setError("Please enter only numbers")
    }
  }

  const setIsContactSent = () => {
    setContactSent(false)
  }

  const sendEmail = async e => {
    e.preventDefault()
    e.persist()
    if (error !== "") {
      return
    }
    if (formIsValid) {
      try {
        setIsSending(true)
        const data = await emailjs.sendForm(
          "service_psjv8k9",
          "template_8rn7rw6",
          e.target,
          "rIHXulH9lFawIJ1jI"
        )
        if (data.status === 200) {
          setContactSent(true)
          e.target.reset()
          toast.success(
            "Thank you for sending us a message. Our team will reply to you as soon as possible.!"
          )
        }
      } catch (error) {
        console.log(error.text)
        toast.error("Failed to send email. Please try again later.")
      } finally {
        setIsSending(false)
        // Reset form and state
        setName("")
        setEmail("")
        setTelephone("")
        setMessage("")
        setFormIsValid(false)
      }
    }
  }
  return (
    <Container>
      <ToastContainer />
      <ColumnOne>
        <Head>
          <Title>Contact us</Title>
          <P>
            We would like to hear from you! Please leave a message and We'll
            reply as soon as we can. Make sure to supply your email address so
            we can respond.
          </P>
          <P>All fields are mandatory.</P>
        </Head>{" "}
        {!contactSent ? (
          <Form onSubmit={sendEmail}>
            <FormInput>
              <Label>Your Name *</Label>
              <Input
                type="text"
                id="fullname"
                name="from_name"
                value={name}
                onChange={handleNameChange}
              />
            </FormInput>
            <FormInput>
              <Label>Your Email *</Label>
              <Input
                type="email"
                id="email"
                name="from_email"
                value={email}
                onChange={handleEmailChange}
              />
            </FormInput>
            <FormInput>
              <Label>Mobile Number *</Label>
              <Input
                type="text"
                id="telephone"
                name="telephone"
                onChange={handleTelephoneChange}
                value={telephone}
              />
              {error && (
                <p style={{ color: "red", paddingTop: ".3rem" }}>{error}</p>
              )}
            </FormInput>
            <FormInput>
              <Label>Your Message *</Label>
              <InputMessage
                name="message"
                id="message"
                value={message}
                onChange={handleMessageChange}
              />
            </FormInput>

            <ButtonContainer>
              <Button
                disabled={!formIsValid}
                as="button"
                type="submit"
                round="true"
                css={`
                  @media screen and(max-width: 768px) {
                    width: 100%;
                    min-width: 350px;
                  }
                  @media screen and(max-width: 400px) {
                    width: 100%;
                    min-width: 250px;
                  }
                `}
              >
                {isSending ? "Sending..." : "Send"}
              </Button>
            </ButtonContainer>
            <div
              css={`
                display: flex;
                align-items: flex-start;
                gap: 0.5rem;
              `}
            >
              <input
                type="checkbox"
                id="privacyPolicy"
                name="smsAgreement"
                value="agree"
                css={`
                  margin-top: 0.2rem;
                  cursor: pointer;
                `}
              />
              <PrivacyText for="smsAgreement">
                I agree to be contacted via text message by Writers Way
                Solutions. Message rates may apply. I can opt-out at any time by
                replying STOP. Click here to view our{" "}
                <NavLink to="/privacy">
                  SMS Notification Opt-In Policy,{" "}
                </NavLink>
                <NavLink to="/compliance-policy">Privacy Policy and </NavLink>
                <NavLink to="/terms-cons">Terms & Conditions</NavLink>
              </PrivacyText>
            </div>
          </Form>
        ) : (
          <div
            css={`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-bottom: 2rem;
            `}
          >
            <Image src={messageSentVector} />
            <ThankYouText>
              Thank you for sending us a message. Our team will reply to you as
              soon as possible.
            </ThankYouText>
            <ButtonContainer>
              <Button
                as="button"
                onClick={setIsContactSent}
                round="true"
                css={`
                  @media screen and(max-width: 768px) {
                    width: 100%;
                    min-width: 350px;
                  }
                  @media screen and(max-width: 400px) {
                    width: 100%;
                    min-width: 250px;
                  }
                `}
              >
                Send another message
              </Button>
            </ButtonContainer>
          </div>
        )}
      </ColumnOne>

      <ColumnTwo>
        <ImageContainer>
          <ImageSvg src={contact} />
        </ImageContainer>

        <ContactInfo>
          <InfoH>Contact us:</InfoH>
          <InfoP>
            <span>Email :</span> customersupport@writerswaysolutions.com
          </InfoP>
          <InfoP>
            <span>Contact Number :</span> 1-888-666-4258
          </InfoP>
        </ContactInfo>
        <ContactInfo>
          <InfoH>Business Hours:</InfoH>
          <InfoP>
            <span>Mondays to Fridays:</span> 10:00am – 8:00pm EST
          </InfoP>
          <InfoP>
            <span>Saturdays:</span> 12:00am – 6:00pm EST
          </InfoP>
          <InfoP>
            <span>Sundays:</span> Closed
          </InfoP>
        </ContactInfo>
      </ColumnTwo>
    </Container>
  )
}

export default Contact

const Container = styled.div`
  display: flex;
  padding: 4rem calc((100vw - 1200px) / 2);
  padding-top: 2rem;
  ${"" /* background:red;  */}
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-top: 2rem;
  }
`
const ColumnOne = styled.div`
  ${"" /* background:yellow; */}
  width:100%;
  padding: 0 1rem;

  h5 {
    font-weight: 500;
    font-size: clamp(0.8rem, 5vw, 1.3rem);
  }
`
const Head = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`
const Title = styled.h1`
  margin-bottom: 1rem;
  font-size: 70px;
  color: ${props => props.theme.colors.main1};

  @media screen and (max-width: 767px) {
    font-size: 3rem;
  } ;
`
const P = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 1rem;
  color: #111;
`
const Form = styled.form`
  ${"" /* background:red; */}
  display:flex;
  flex-direction: column;
`
const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`
const Label = styled.p`
  font-size: 14px;
  letter-spacing: 1.3px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
  font-weight: 500;
  color: #111;
`

const PrivacyText = styled.label`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #111;
  text-align: justify;
`

const NavLink = styled(Link)`
  font-size: 12px;
  letter-spacing: 1.3px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
  font-weight: 500;
  color: ${props => props.theme.colors.main1};
  cursor: pointer;
  /* text-decoration: underline; */
`

const Input = styled.input`
  padding: 0.9rem 1rem;
  outline: none;
  width: 100%;
  border-radius: 50px;
  border-width: 0px;
  border: none;
  background: rgba(12, 15, 74, 0.04);
`
const InputMessage = styled.textarea`
  width: 100%;
  padding: 1rem 1.5rem;
  resize: none;
  font-size: 14px;
  color: #111;
  border-width: 0px;
  border: none;
  outline: none;
  border-radius: 10px;
  min-height: 120px;
  border-color: lightgrey;
  margin-bottom: 0.5rem;
  background: rgba(12, 15, 74, 0.04);
`

const ColumnTwo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  width: 100%;
  margin-bottom: 2rem;
`
const ImageSvg = styled.img`
  width: 100%;
  overflow: hidden;
`

const Image = styled.img`
  width: 300px;
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 4rem;
  width: 100%;
  @media screen and (max-width: 362px) {
    padding: 0 2rem;
  }
  @media screen and (max-width: 298px) {
    padding: 0 1rem;
  }
  @media screen and (max-width: 266px) {
    padding: 0;
  }
`
const InfoH = styled.h3`
  margin-bottom: 0.5rem;
  font-size: clamp(1rem, 3vw, 1.2rem);
  color: ${props => props.theme.colors.main1};
`
const ThankYouText = styled.span`
  margin-bottom: 0.5rem;
  font-size: clamp(0.8rem, 3vw, 1.2rem);
  color: ${props => props.theme.colors.accent3};
  text-align: center;
`

const InfoP = styled.p`
  padding-left: 0.5rem;
  margin-bottom: 0.8rem;
  font-size: clamp(0.6rem, 3vw, 1rem);
  font-weight: 500;
  color: #333;
  span {
    font-weight: 700;
    color: ${props => props.theme.colors.accent7};
  }
`
const ButtonContainer = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 55px;
  margin-bottom: 1rem;
  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`
